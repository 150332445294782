<template lang="">
    <div class="xs:w-full m-2 mb-2 bg-white border border-gray-200 rounded-lg shadow-2xl dark:bg-gray-800 dark:border-gray-700">
        <a :href="'/'+article.slug" class="relative" :aria-label="'Read '+article.title">
            <img class="w-full rounded-t-lg" :srcset="'/storage/'+article.banner_image" alt="" loading="lazy"/>
        </a>
        <div class="flex flex-wrap p-5">
            <div class="flex left-2 items-center gap-4 w-full">
                <img class="w-10 h-10 rounded-full" :src="'/storage/resources/avatars/codeducator.png'" alt="" >
                <div class="font-semibold">
                    <div>Codeducator</div>
                </div>
            </div>
            <a :href="'/'+article.slug">
                <h2 class="my-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">{{article.title}}</h2>
            </a>
            <p class="text-md mb-2 text-gray-500 dark:text-white w-full">Posted on {{publishedDate}}</p>
            <a :href="'/tag/'+tag.slug.en" :to="{ name: 'tag.index', params: { tag: tag.slug.en }}" v-for="tag in article.tags" class="inline hover:text-white border border-cyan-700 hover:bg-teal-800 focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-sm px-4 py-2.5 mr-1 text-left mb-2 dark:border-teal-500 dark:text-teal-500 dark:hover:text-white dark:hover:bg-teal-500 dark:focus:ring-teal-800">
                {{tag.name.en}}
            </a>
        </div>
    </div>
</template>
<script>
import moment from 'moment';

export default {
    props: {
        article: {
            type: Object,
            default: () => ({}),
        }
    },
    computed: {
        publishedDate: function(){
         return moment(this.article.published_at).format('MMMM Do YYYY'); 
        }
    },
}
</script>
<style lang="">
    
</style>