<template lang="">
    <section class="h-screen bg-center bg-no-repeat bg-[url('https://picsum.photos/1920/1280')] bg-gray-700 bg-blend-multiply">
        <div class="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
            <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl"> "Personal growth is about progress, not perfection."</h1>
            <p class="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">
                - Hal Elrod
            </p>
            
        </div>
    </section>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>