<template lang="">
    <div class="flex flex-wrap mt-14">
        <h2 class="w-full mb-4 text-3xl font-extrabold leading-none tracking-tight text-gray-900 md:text-4xl dark:text-white">Technology Management</h2>
        <div class="w-2/5 px-2">
            <h4 class="text-2xl font-bold dark:text-white">{{ id == null ? 'Add' : 'Update'}} Technology</h4>
            <form>
                <div class="grid gap-6 mb-6">
                    <div>
                        <label for="meta_title" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Meta Title</label>
                        <input
                            type="text"
                            id="meta_title"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500" placeholder="Meta Title"
                            v-model="metaTitle"
                        >
                        <p class="mt-2 text-lg text-red-600 dark:text-red-500" v-if="errors && errors.meta_title">
                            <span class="font-medium">*{{errors.meta_title[0]}}</span>
                        </p>
                    </div>
                    <div>
                        <label for="meta_description" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Meta Description</label>
                        <input 
                            type="text"
                            id="meta_description"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500" placeholder="Meta Description"
                            v-model="metaDescription"
                        >
                        <p class="mt-2 text-lg text-red-600 dark:text-red-500" v-if="errors && errors.meta_description">
                            <span class="font-medium">*{{errors.meta_description[0]}}</span>
                        </p>
                    </div>
                    <div>
                        <label for="name" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Title</label>
                        <input type="text" 
                            id="name"
                            class="bg-gray-50 border border-gray-300' text-gray-900 text-lg rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                            placeholder="Title"
                            v-model="name">
                        <p class="mt-2 text-lg text-red-600 dark:text-red-500" v-if="errors && errors.name">
                            <span class="font-medium">*{{errors.name[0]}}</span>
                        </p>
                    </div>
                    <div>
                        <label for="slug" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Slug</label>
                        <input v-if="id==null" type="text" 
                            id="slug"
                            class="bg-gray-50 border border-gray-300' text-gray-900 text-lg rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                            placeholder="Slug"
                            v-model="slugified">
                        <input v-else type="text" 
                            id="slug"
                            class="bg-gray-50 border border-gray-300' text-gray-900 text-lg rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                            placeholder="Slug"
                            v-model="slug">
                        <p class="mt-2 text-lg text-red-600 dark:text-red-500" v-if="errors && errors.slug">
                            <span class="font-medium">*{{errors.slug[0]}}</span>
                        </p>
                    </div> 
                </div>
                <div class="grid gap-6 mb-6 md:grid-cols-2">
                    <div class="mb-6">
                        <label for="status" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Status</label>
                            <v-select
                                :options="[{label: 'Draft', code: 'draft'}, {label: 'Published', code: 'published'}]"
                                :reduce="status => status.code" 
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                                v-model="status"
                            ></v-select>
                        <p class="mt-2 text-lg text-red-600 dark:text-red-500" v-if="errors && errors.status">
                            <span class="font-medium">*{{errors.status[0]}}</span>
                        </p>
                    </div> 
                </div>
                <div v-if="processing == true">
                    <div role="status">
                        <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-teal-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <button v-if="processing == false" v-on:click="submit" class="text-white bg-teal-700 hover:bg-teal-800 focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-lg w-full sm:w-auto px-5 py-2.5 text-center dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-teal-800 mx-1">Submit</button>
                <button v-if="processing == false" type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-lg w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 mx-1" @click="resetInputs">Cancel</button>
            </form> 
        </div>
        <div class="w-3/5 px-2">
            <h4 class="text-2xl font-bold dark:text-white">List of Technologies</h4>
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div class="flex items-center justify-between flex-column flex-wrap md:flex-row space-y-4 md:space-y-0 pb-4 bg-white dark:bg-gray-900">
                    <div>
                        <button id="dropdownActionButton" data-dropdown-toggle="dropdownAction" class="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-lg px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" type="button">
                            <span class="sr-only">Action button</span>
                            Action
                            <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                            </svg>
                        </button>
                        <!-- Dropdown menu -->
                        <div id="dropdownAction" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                            <ul class="py-1 text-lg text-gray-700 dark:text-gray-200" aria-labelledby="dropdownActionButton">
                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Reward</a>
                                </li>
                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Promote</a>
                                </li>
                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Activate account</a>
                                </li>
                            </ul>
                            <div class="py-1">
                                <a href="#" class="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete User</a>
                            </div>
                        </div>
                    </div>
                    <label for="table-search" class="sr-only">Search</label>
                    <div class="relative">
                        <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <input type="text" id="table-search-users" class="block p-2 ps-10 text-lg text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-teal-500 focus:border-teal-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500" placeholder="Search for users">
                    </div>
                </div>
                <table class="w-full text-lg text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3" width="45%">
                                Title
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Slug
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Status
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" v-for="technology in technologies">
                            <td scope="row" class="flex items-center px-6 py-4 text-gray-900 dark:text-white">
                                <div class="ps-3">
                                    <div class="text-base font-semibold">{{technology.name}}</div>
                                </div>  
                            </td>
                            <td class="px-6 py-4">
                                <a :href="'/'+technology.slug" target="_blank">
                                    {{technology.slug}}
                                </a>
                            </td>
                            <td class="px-6 py-4">
                                <div class="flex items-center">
                                    <div class="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div> {{technology.status.toUpperCase()}}
                                </div>
                            </td>
                            <td class="flex px-6 py-4">
                                <button type="button" class="px-1 font-medium text-teal-600 dark:text-teal-500 hover:underline" @click="editTechnology(technology.id)">
                                    <svg class="w-6 h-6 text-teal-600 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 21">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7.418 17.861 1 20l2.139-6.418m4.279 4.279 10.7-10.7a3.027 3.027 0 0 0-2.14-5.165c-.802 0-1.571.319-2.139.886l-10.7 10.7m4.279 4.279-4.279-4.279m2.139 2.14 7.844-7.844m-1.426-2.853 4.279 4.279"/>
                                    </svg>
                                </button>
                                <button type="button" class="px-1 font-medium text-red-600 dark:text-teal-500 hover:underline" @click="confirmDelete(technology.id)">
                                    <svg class="w-6 h-6 text-red-600 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"/>
                                    </svg>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import vSelect from 'vue-select';
import Swal from 'sweetalert2'

import 'sweetalert2/src/sweetalert2.scss'
import '@sweetalert2/themes/minimal/minimal.scss';

export default {
    name: 'app',
    components: {
        vSelect
    },
    data() {
        return {
            processing: false,
            errors: [],
            technologies: [],
            id: null,
            metaTitle: null,
            metaDescription: null,
            name: null,
            slug: null,
            status: null,
        };
    },
    mounted() {
        this.retrieveTechnologies();
    },
    computed: {
        slugified: function() {
            var slug = this.slugify(this.name);
            return slug;
        },
    },
    methods: {
        resetInputs(){
            this.errors = [];
            this.id = null,
            this.metaTitle = null;
            this.metaDescription = null;
            this.name = null;
            this.slug = null;
            this.status = null;
        },
        slugify: function(name) {
            var slug = "";
            if(name != null){
                // Change to lower case
                var nameLower = name.toLowerCase();
                // Letter "e"
                slug = nameLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
                // Letter "a"
                slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
                // Letter "o"
                slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
                // Letter "u"
                slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
                // Letter "d"
                slug = slug.replace(/đ/gi, 'd');
                // Trim the last whitespace
                slug = slug.replace(/\s*$/g, '');
                // Change whitespace to "-"
                slug = slug.replace(/\s+/g, '-');
            }
            return slug;
        },
        async showAlert(icon = 'info', message = 'Success.', timer = 7000){
            Swal.fire({
                position: 'top-end',
                icon: icon,
                title: message,
                showConfirmButton: false,
                toast: true,
                timer: timer
            });
        },
        async retrieveTechnologies(){
            try {
                let response = await axios.get('technologies/list/all');
                this.technologies = response.data;
            } catch (error) {
                if(error.response.data.errors){
                    const errors = error.response.data.errors;
                    for (const key in errors) {
                        console.log(errors[key])
                    }
                }
            }
        },
        async editTechnology(id){
            try {
                let response = await axios.get('technologies/'+id+'/edit');
                this.id = response.data.id;
                this.metaTitle = response.data.meta_title;
                this.metaDescription = response.data.meta_description;
                this.name = response.data.name;
                this.slug = response.data.slug;
                this.status = response.data.status;

            } catch (error) {
                console.error(error);
            }
        },
        async confirmDelete(id){
            try {
                Swal.fire({
                title: 'Are you sure you want to delete the record?',
                icon: 'question',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Proceed',
                confirmButtonColor: '#3085d6',
                denyButtonText: `Cancel`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteTechnology(id);                                  
                    }
                })

            } catch (error) {
                console.log(error);
            }
        },
        async deleteTechnology(id){
            try {
                let response = await axios.delete('technologies/'+id,{
                                    _token: document.querySelector('meta[name="csrf-token"]').content })
                this.retrieveTechnologies();
            } catch (error) {
                console.log(error);
            }
        },
        submit(e){
            e.preventDefault();
            this.id == null ? this.saveTechnology() : this.updateTechnology();
        },
        async saveTechnology(){
            let self = this;
            this.processing = true;

            await axios.post('technologies', {  
                _token: document.querySelector('meta[name="csrf-token"]').content,
                meta_title: this.metaTitle,
                meta_description: this.metaDescription,
                name: this.name,
                slug: this.slugified,
                status: this.status
            })  
            .then(function () {  
                self.resetInputs();
                self.retrieveTechnologies();
                self.showAlert('success', 'Record saved.');
            })  
            .catch(function (error) {  
                self.errors = error.response.data.errors;  
            });
            
            this.processing = false;
        },
        async updateTechnology(){

            let self = this;
            this.processing = true;

            await axios.post('technologies/'+this.id, {
                _token: document.querySelector('meta[name="csrf-token"]').content,
                _method: 'patch',
                meta_title: this.metaTitle,
                meta_description: this.metaDescription,
                name: this.name,
                slug: this.slug,
                status: this.status
            })  
            .then(function () {  
                self.resetInputs();
                self.retrieveTechnologies();
                self.showAlert('success', 'Record saved.');
            })  
            .catch(function (error) {  
                self.errors = error.response.data.errors;  
            }); 

            this.processing = false;
        }
    },
}
</script>
<style lang="css">
@import 'vue-select/dist/vue-select.css';
</style>