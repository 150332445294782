<template lang="">
    <section class="bg-center bg-teal-700 h-screen">
        <div class="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
            <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
                <mark class="px-2 text-white bg-teal-600 rounded dark:bg-teal-500">Codeducator</mark>
                - Programming Insights for Beginners.
            </h1>
            <p class="mb-8 text-2xl font-normal text-gray-300 lg:text-3xl sm:px-16 lg:px-48">
                Discover the gateway to your coding journey with <mark class="text-white bg-teal-500 rounded dark:bg-teal-500">Codeducator</mark>.<br/>Unleashing programming insights for beginners and aspiring computer programmers.<br/>Let's code a brighter future together!
            </p>
            <div class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
                <a href="#" @click="getStarted" class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-teal-500 hover:bg-teal-800 focus:ring-4 focus:ring-teal-300 dark:focus:ring-teal-900">
                    Get started
                    <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </a>
            </div>
        </div>
    </section>
    <div class="flex flex-wrap max-w-screen-xl mx-auto p-4" id="article-list">
        <div class="justify-center w-full md:w-3/4">
            <!-- <div v-if="articles.length > 0" class="flex">
            <h2 class="text-3xl font-bold dark:text-white m-2">Featured and Latest Articles</h2>
            </div> -->
            <div v-if="articles.length > 0" class="grid grid-cols-1 md:grid-cols-3 mx-auto">
                <ArticleCard v-for="article in articles" 
                    :article="article"
                />
            </div>
            <div class="flex w-full mx-2 mb-2" v-show="loading">
                <div class="flex w-full" role="status">
                    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-teal-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span class="m-2">Loading...</span>
                </div>
            </div>
            <div v-if="(articles.length > 0) && (articleCount > articlesShown)" class="flex flex-col items-center pt-4">
                <!-- <span class="text-md dark:text-gray-400">
                    Showing <span class="font-semibold text-gray-900 dark:text-white">1</span> to <span class="font-semibold text-gray-900 dark:text-white">{{ articlesShown }}</span> of <span class="font-semibold text-gray-900 dark:text-white">{{ articleCount}}</span>
                </span> -->
                <button @click="retrieveArticles" class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-teal-500 hover:bg-teal-800 focus:ring-4 focus:ring-teal-300 dark:focus:ring-teal-900">
                    Show more
                    <svg class="w-3.5 h-3.5 ms-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="#ffffff" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
                    </svg>
                </button>
            </div>
            <div v-if="articles.length === 0 && loading == false" class="justify-center text-center w-full">
                <p class="my-3 text-lg md:text-xl dark:text-gray-400">No results found.</p>
            </div>
        </div>
        
        <div class="w-full md:w-1/4 pl-2 hidden md:flex md:flex-col">
            <a v-if="technologies.length > 0" href="#" class="sticky top-24 block max-w-sm max-h-min p-6 border-0 border-gray-200 rounded-lg shadow">
                <h2 class="mb-2 text-2xl font-bold tracking-tight text-dark dark:text-white">Technologies:</h2>
                <ul class="max-w-md divide-y divide-gray-200 dark:divide-gray-700">
                    <li class="pt-3 pb-0 sm:pt-4" v-for="technology in technologies">
                        <div class="flex items-center space-x-4 rtl:space-x-reverse">
                            <div class="flex-1 min-w-0">
                                <a :href="'/technology/'+technology.slug" :aria-label="'Show '+technology.name+' related articles'">
                                    <p class="text-lg font-medium text-gray-900 truncate dark:text-white">
                                        {{technology.name}}
                                        <span v-if="technology.articles.length > 10" class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                                            {{technology.articles.length}}
                                        </span>
                                    </p>
                                </a>
                                
                            </div>
                            <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                <a :href="'/technology/'+technology.slug" :aria-label="'Show '+technology.name+' related articles'">
                                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM241 377c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l87-87-87-87c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L345 239c9.4 9.4 9.4 24.6 0 33.9L241 377z"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>

            </a>
        </div>
        <transition name="fade">
            <div id="pagetop" class="fixed right-7 bottom-24" v-show="scY > 300" @click="scrollToTop">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" class="opacity-50 hover:opacity-100" viewBox="0 0 512 512">
                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM377 271c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-87-87-87 87c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 167c9.4-9.4 24.6-9.4 33.9 0L377 271z"/>
            </svg>
            </div>
        </transition>
    </div>
</template>
<script>
import ArticleCard from './cards/ArticleCard.vue';

export default {
    name: 'app',
    components:{
        ArticleCard
    },
    data() {
        return {
            scTimer: 0,
            scY: 0,
            searchKey: null,
            loading: false,
            technologies: [],
            articles: [],
            articlesShown: 1,
            articleCount: 1,
            current_page: 1,
            last_page: 2
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
        //window.addEventListener('scrollend', this.retrieveArticles);
    },
    unmounted () {
        //window.removeEventListener('scrollend', this.retrieveArticles);
    },
    mounted() {
        this.retrieveArticles();
        this.retrieveTechnologies();
    },
    methods: {
        async retrieveTechnologies(){
            try {
                let response = await axios.get('technologies/list');
                this.technologies = response.data;
            } catch (error) {
                if(error.response.data.errors){
                    const errors = error.response.data.errors;
                    for (const key in errors) {
                        console.log(errors[key])
                    }
                }
            }
        },
        async retrieveArticles(){

            if(this.current_page > this.last_page){ return; }
            
            this.loading = true;

            try {

                if((this.current_page == 1 || (this.current_page <= this.last_page)) && this.searchKey == null){
                    let response = await axios.get('articles/list?page='+this.current_page);
                    
                    // this.articles.push(response.data.data);
                    this.last_page = response.data.last_page;
                    response.data.data.forEach((value, index) => {
                        this.articles.push(value);
                    });
                    this.articlesShown = response.data.to;
                    this.articleCount = response.data.total;
                    this.current_page++; 
                }
            } catch (error) {
                if(error.response.data.errors){
                    const errors = error.response.data.errors;
                    for (const key in errors) {
                        console.log(errors[key])
                    }
                }
            }

            this.loading = false;
        },
        async searchArticles(){
            this.loading = true;
            let response = await axios.post('/search', {
                _token: document.querySelector('meta[name="csrf-token"]').content,
                keyword: this.searchKey
            })
            .catch(function (error) {  
                console.log(error);
            });
            this.articlesShown = response.data.length;
            this.articleCount = response.data.length;
            this.articles = response.data;
            this.loading = false;
        },
        async searchArticlesByTag(tag){
            this.loading = true;
            let response = await axios.post('/search', {
                _token: document.querySelector('meta[name="csrf-token"]').content,
                tag: tag
            })
            .catch(function (error) {  
                console.log(error);
            });
            this.articles = response.data;
            this.loading = false;
        },
        handleScroll() {
            if (this.scTimer) return;
            this.scTimer = setTimeout(() => {
            this.scY = window.scrollY;
            clearTimeout(this.scTimer);
            this.scTimer = 0;
            }, 100);
        },
        scrollToTop() {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        },
        getStarted() {
            window.scrollTo({ top: window.innerHeight, left: 0, behavior: 'smooth' });
        }
    },
}
</script>
<style lang="">
    
</style>