<template lang="">
    <section class="bg-white dark:bg-gray-900">
        <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
            <h2 class="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Codeducator</h2>
            <div class="grid pt-8 text-left border-t border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
                <div>
                    <div class="mb-10">                        
                        <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                            Welcome to Codeducator
                        </h3>
                        <p class="text-gray-500 dark:text-gray-400">
                            Welcome to Codeducator, your ultimate destination for comprehensive coding insights and educational resources! Founded and administered by Alan, Codeducator is a passion project born out of a deep love for coding and a commitment to empowering aspiring developers, students, and professionals alike.
                        </p>
                    </div>
                    <div class="mb-10">
                        <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                            Meet Alan
                        </h3>
                        <p class="text-gray-500 dark:text-gray-400">
                            As the creator and administrator of Codeducator, Alan brings a wealth of experience and expertise to the table. With a background as a full-stack developer and a seasoned college instructor specializing in software engineering and computer programming, Alan is dedicated to bridging the gap between theory and practice in the ever-evolving world of coding.
                        </p>
                    </div>
                    <div class="mb-10">
                        <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                            What We Offer
                        </h3>
                        <p class="text-gray-500 dark:text-gray-400">
                            <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                                <li>
                                    Comprehensive Insights: Dive deep into the world of coding with our wide range of insightful articles, tutorials, and guides covering everything from fundamental concepts to advanced techniques.
                                </li>
                                <li>
                                    Educational Resources: Access a treasure trove of educational resources, including coding exercises, practice projects, and curated learning materials designed to enhance your understanding and proficiency in coding.
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div>
                    <div class="mb-10">
                        <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                            Our Mission
                        </h3>
                        <p class="text-gray-500 dark:text-gray-400">
                            At Codeducator, our mission is simple: to provide you with the knowledge, tools, and inspiration you need to succeed in your coding journey. Whether you're just starting out on your coding odyssey or looking to sharpen your skills and stay ahead of the curve, we're here to support you every step of the way.
                        </p>
                    </div>
                    <div class="mb-10">
                        <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                            Get in Touch
                        </h3>
                        <p class="text-gray-500 dark:text-gray-400">
                            Have questions, feedback, or ideas for future content? We'd love to hear from you! Feel free to contact us by clicking at the "Get in Touch Button" above and let us know how we can continue to improve your experience at Codeducator.
                        </p>
                    </div>
                    <div class="mb-10">
                        <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                            Connect with Us
                        </h3>
                        <p class="text-gray-500 dark:text-gray-400">
                            <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                                <li>
                                    GitHub: Explore <a class="text-teal-700" href="https://github.com/alanretubis" target="_blank">Alan's</a> GitHub account to discover coding projects, contributions, and repositories. Follow us on GitHub to stay updated on our latest coding endeavors!
                                </li>
                                <li>
                                    Facebook: Like our <a class="text-teal-700" href="https://www.facebook.com/codeducator" target="_blank">Codeducator Facebook page</a> to stay connected with our community, receive updates on new content, and engage with fellow coding enthusiasts!
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>