import './bootstrap';
import 'flowbite';

//Import Froala Editor 
import 'froala-editor/js/plugins.pkgd.min.js';
//Import third party plugins
// import 'froala-editor/js/third_party/embedly.min';
// import 'froala-editor/js/third_party/font_awesome.min';
// import 'froala-editor/js/third_party/spell_checker.min';
// import 'froala-editor/js/third_party/image_tui.min';
// Import Froala Editor css files.
// import 'froala-editor/css/froala_editor.pkgd.min.css';
// import 'froala-editor/css/froala_style.min.css';

import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import VueFroala from 'vue-froala-wysiwyg';

import Homepage from './components/Homepage.vue';
import DashboardPage from './components/DashboardPage.vue'
import ArticleManagementPage from './components/ArticleManagementPage.vue';
import TagPage from './components/TagPage.vue';
import TechnologyManagementPage from './components/TechnologyManagementPage.vue';
import TechnologyPage from './components/TechnologyPage.vue';
import SearchPage from './components/SearchPage.vue';
import AboutPage from './components/AboutPage.vue';
import PrivacyPolicyPage from './components/PrivacyPolicyPage.vue';
import TermsAndConditionsPage from './components/TermsAndConditionsPage.vue';
import LeadManagementPage from "./components/LeadManagementPage.vue";

const routes = [
    { path: '/', name: 'home', component: Homepage },
    { path: '/tag/:slug', name: 'tag.index', component: TagPage },
    { path: '/about', name: 'about.index', component: AboutPage },
    { path: '/admin', name: 'admin.index', component: DashboardPage },
    { path: '/articles', name: 'articles.index', component: ArticleManagementPage },
    { path: '/leads', name: 'leads.index', component: LeadManagementPage },
    { path: '/privacy-policy', name: 'privacy-policy.index', component: PrivacyPolicyPage },
    { path: '/search', name: 'search.index', component: SearchPage },
    { path: '/technologies', name: 'technologies.index', component: TechnologyManagementPage },
    { path: '/technology/:slug', name: 'technology.index', component: TechnologyPage },
    { path: '/terms-and-conditions', name: 'terms-and-conditions.index', component: TermsAndConditionsPage },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

const app = createApp({})
            .use(router)
            .use(VueFroala);
            app.mount("#app");