<template lang="">
    <div class="flex flex-wrap mt-14">
        <h2 class="w-full mb-4 text-3xl font-extrabold leading-none tracking-tight text-gray-900 md:text-4xl dark:text-white">Lead Management</h2>
        <div class="w-full px-2">
            <h4 class="text-2xl font-bold dark:text-white">List of Leads</h4>
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div class="flex items-center justify-between flex-column flex-wrap md:flex-row space-y-4 md:space-y-0 pb-4 bg-white dark:bg-gray-900">
                    <div>
                        <button id="dropdownActionButton" data-dropdown-toggle="dropdownAction" class="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-lg px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" type="button">
                            <span class="sr-only">Action button</span>
                            Action
                            <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                            </svg>
                        </button>
                        <!-- Dropdown menu -->
                        <div id="dropdownAction" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                            <ul class="py-1 text-lg text-gray-700 dark:text-gray-200" aria-labelledby="dropdownActionButton">
                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Reward</a>
                                </li>
                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Promote</a>
                                </li>
                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Activate account</a>
                                </li>
                            </ul>
                            <div class="py-1">
                                <a href="#" class="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete User</a>
                            </div>
                        </div>
                    </div>
                    <label for="table-search" class="sr-only">Search</label>
                    <div class="relative">
                        <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <input type="text" id="table-search-users" class="block p-2 ps-10 text-lg text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-teal-500 focus:border-teal-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500" placeholder="Search for users">
                    </div>
                </div>
                <table class="w-full text-lg text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3" width="25%">
                                Name
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Email
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Subject
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Message
                            </th>
                            <th>
                                Date and Time
                            </th>
                            <th>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" v-for="lead in leads">
                            <td scope="row" class="flex items-center px-6 py-4 text-gray-900 dark:text-white">
                                <div class="ps-3">
                                    <div class="text-base font-semibold">{{lead.name}}</div>
                                </div>  
                            </td>
                            <td class="px-6 py-4">
                                {{lead.email}}
                            </td>
                            <td class="px-6 py-4">
                                {{lead.subject.substring(0, 30)}}{{ lead.subject.length > 30 ? '...' : ''}}
                            </td>
                            <td class="px-6 py-4">
                               {{lead.content.substring(0, 30)}}{{ lead.content.length > 30 ? '...' : ''}}
                            </td>
                            <td class="px-6 py-4">
                               {{leadDate(lead.created_at)}}
                            </td>
                            <td class="flex px-6 py-4">
                                <button type="button" class="px-1 font-medium text-teal-600 dark:text-teal-500 hover:underline" @click="showLead(lead)">
                                    <svg class="w-6 h-6 text-teal-600 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import vSelect from 'vue-select';
import Swal from 'sweetalert2'

import 'sweetalert2/src/sweetalert2.scss'
import '@sweetalert2/themes/minimal/minimal.scss';

export default {
    name: 'app',
    components: {
        vSelect
    },
    data() {
        return {
            processing: false,
            errors: [],
            leads: [],
            id: null,
            metaTitle: null,
            metaDescription: null,
            name: null,
            slug: null,
            status: null,
        };
    },
    mounted() {
        this.retrieveLeads();
    },
    computed: {
        slugified: function() {
            var slug = this.slugify(this.name);
            return slug;
        },
    },
    methods: {
        resetInputs(){
            this.errors = [];
            this.id = null,
            this.metaTitle = null;
            this.metaDescription = null;
            this.name = null;
            this.slug = null;
            this.status = null;
        },
        slugify: function(name) {
            var slug = "";
            if(name != null){
                // Change to lower case
                var nameLower = name.toLowerCase();
                // Letter "e"
                slug = nameLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
                // Letter "a"
                slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
                // Letter "o"
                slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
                // Letter "u"
                slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
                // Letter "d"
                slug = slug.replace(/đ/gi, 'd');
                // Trim the last whitespace
                slug = slug.replace(/\s*$/g, '');
                // Change whitespace to "-"
                slug = slug.replace(/\s+/g, '-');
            }
            return slug;
        },
        async showAlert(icon = 'info', message = 'Success.', timer = 7000){
            Swal.fire({
                position: 'top-end',
                icon: icon,
                title: message,
                showConfirmButton: false,
                toast: true,
                timer: timer
            });
        },
        async retrieveLeads(){
            try {
                let response = await axios.get('leads/list/all');
                this.leads = response.data;
            } catch (error) {
                if(error.response.data.errors){
                    const errors = error.response.data.errors;
                    for (const key in errors) {
                        console.log(errors[key])
                    }
                }
            }
        },
        async showLead(lead){
            try {
                
                Swal.fire({
                html: '<div style="text-align: left;"><strong>Subject:</strong><br/>'+lead.subject +'<br/><br/><strong>Message:</strong><br/>'+lead.content+'<br/><br/><strong>From:</strong> '+lead.name+'<br/><strong>Email:</strong> '+lead.email+'</div>',
                showCloseButton: true,
                })

            } catch (error) {
                console.error(error);
            }
        },
        async confirmDelete(id){
            try {
                Swal.fire({
                title: 'Are you sure you want to delete the record?',
                icon: 'question',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Proceed',
                confirmButtonColor: '#3085d6',
                denyButtonText: `Cancel`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteLead(id);                                  
                    }
                })

            } catch (error) {
                console.log(error);
            }
        },
        async deleteLead(id){
            try {
                let response = await axios.delete('leads/'+id,{
                                    _token: document.querySelector('meta[name="csrf-token"]').content })
                this.retrieveLeads();
            } catch (error) {
                console.log(error);
            }
        },
        submit(e){
            e.preventDefault();
            this.id == null ? this.saveLead() : this.updateLead();
        },
        async saveLead(){
            let self = this;
            this.processing = true;

            await axios.post('leads', {  
                _token: document.querySelector('meta[name="csrf-token"]').content,
                meta_title: this.metaTitle,
                meta_description: this.metaDescription,
                name: this.name,
                slug: this.slugified,
                status: this.status
            })  
            .then(function () {  
                self.resetInputs();
                self.retrieveLeads();
                self.showAlert('success', 'Record saved.');
            })  
            .catch(function (error) {  
                self.errors = error.response.data.errors;  
            });
            
            this.processing = false;
        },
        async updateLead(){

            let self = this;
            this.processing = true;

            await axios.post('leads/'+this.id, {
                _token: document.querySelector('meta[name="csrf-token"]').content,
                _method: 'patch',
                meta_title: this.metaTitle,
                meta_description: this.metaDescription,
                name: this.name,
                slug: this.slug,
                status: this.status
            })  
            .then(function () {  
                self.resetInputs();
                self.retrieveLeads();
                self.showAlert('success', 'Record saved.');
            })  
            .catch(function (error) {  
                self.errors = error.response.data.errors;  
            }); 

            this.processing = false;
        },
        leadDate: function(date){
            return moment(date).format('MMMM DD, YYYY h:mm:ss a'); 
        },
    },
}
</script>
<style lang="css">
@import 'vue-select/dist/vue-select.css';
</style>