<template lang="">
    <div class="flex flex-wrap mt-14">
        <h2 class="w-full mb-4 text-3xl font-extrabold leading-none tracking-tight text-gray-900 md:text-4xl dark:text-white">Article management</h2>
        <div class="w-full px-2">
            <h4 class="text-2xl font-bold dark:text-white">{{ id == null ? 'Create' : 'Update'}} Article</h4>
            <form>
                <div class="grid gap-6 mb-6">
                    <div class="grid gap-6 mb-6 md:grid-cols-2">
                        <div class="flex flex-col items-start justify-center w-full">
                            <label>Featured Image | OG Image </label>
                            <label for="ogImage" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                    <img class="max-h-48 max-w-full" v-if="ogImagePreview" :src="ogImagePreview" alt="image description">
                                    <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" v-if="!ogImagePreview" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                                    </svg>
                                    <p class="mb-2 text-lg text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span></p>
                                    <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG, GIF, or WEBP (MAX. 1248x600px)</p>
                                </div>
                                <input id="ogImage" type="file" class="hidden" @change="onSelectOgImage"/>
                            </label>
                        </div>
                        <div class="flex flex-col items-start justify-center w-full">
                            <label>Favicon</label>
                            <label for="favicon" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                    <img class="max-h-48 max-w-full" v-if="faviconPreview" :src="faviconPreview" alt="image description">
                                    <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" v-if="!faviconPreview" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                                    </svg>
                                    <p class="mb-2 text-lg text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span></p>
                                    <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 48x48px)</p>
                                </div>
                                <input id="favicon" type="file" class="hidden" @change="onSelectFavicon"/>
                            </label>
                        </div> 
                    </div>
                    <div>
                        <label class="block mb-2 text-lg font-medium text-gray-900 dark:text-white" for="video-upload">Featured Video URL</label>
                        <input class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="featured_video" type="file" accept="video/*" @change="onSelectFeaturedVideo">
                        <p class="mt-2 text-lg text-red-600 dark:text-red-500" v-if="errors && errors.featured_video">
                            <span class="font-medium">*{{errors.featured_video[0]}}</span>
                        </p>
                    </div>
                    <div>
                        <label for="meta_title" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Meta Title</label>
                        <input
                            type="text"
                            id="meta_title"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500" placeholder="Meta Title"
                            v-model="metaTitle"
                        >
                        <p class="mt-2 text-lg text-red-600 dark:text-red-500" v-if="errors && errors.meta_title">
                            <span class="font-medium">*{{errors.meta_title[0]}}</span>
                        </p>
                    </div>
                    <div>
                        <label for="meta_description" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Meta Description</label>
                        <input 
                            type="text"
                            id="meta_description"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500" placeholder="Meta Description"
                            v-model="metaDescription"
                        >
                        <p class="mt-2 text-lg text-red-600 dark:text-red-500" v-if="errors && errors.meta_description">
                            <span class="font-medium">*{{errors.meta_description[0]}}</span>
                        </p>
                    </div>
                    <div>
                        <label for="title" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Title</label>
                        <input type="text" 
                            id="title"
                            class="bg-gray-50 border border-gray-300' text-gray-900 text-lg rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                            placeholder="Title"
                            v-model="title">
                        <p class="mt-2 text-lg text-red-600 dark:text-red-500" v-if="errors && errors.title">
                            <span class="font-medium">*{{errors.title[0]}}</span>
                        </p>
                    </div>
                    <div>
                        <label for="slug" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Slug</label>
                        <input v-if="id==null" type="text" 
                            id="slug"
                            class="bg-gray-50 border border-gray-300' text-gray-900 text-lg rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                            placeholder="Slug"
                            v-model="slugified">
                        <input v-else type="text" 
                            id="slug"
                            class="bg-gray-50 border border-gray-300' text-gray-900 text-lg rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                            placeholder="Slug"
                            v-model="slug">
                        <p class="mt-2 text-lg text-red-600 dark:text-red-500" v-if="errors && errors.slug">
                            <span class="font-medium">*{{errors.slug[0]}}</span>
                        </p>
                    </div> 
                    <div>
                        <label class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Content</label>
                        <froala :tag="'textarea'" :config="config" v-model:value="content"></froala>
                        <p class="mt-2 text-lg text-red-600 dark:text-red-500" v-if="errors && errors.content">
                            <span class="font-medium">*{{errors.content[0]}}</span>
                        </p>
                    </div>
                    <div>
                        <label for="technology" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Technology</label>
                        <v-select 
                            :options="technologies"
                            :reduce="technology => technology.id" label="name"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                            v-model="technology"
                        ></v-select>
                        <p class="mt-2 text-lg text-red-600 dark:text-red-500" v-if="errors && errors.technology">
                            <span class="font-medium">*{{errors.technology[0]}}</span>
                        </p>
                    </div>
                    <div>
                        <label for="tags" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Tags</label>
                        <v-select 
                            :options="[
                                {label: 'Coding Tips and Tricks', code: 'coding-tips-and-tricks'},
                                {label: 'Databases', code: 'databases'},
                                {label: 'Learning Resources', code: 'learning-resources'},
                                {label: 'Mobile App Development', code: 'mobile-app-development'},
                                {label: 'Programming', code: 'programming'},
                                {label: 'Programming Languages', code: 'programming-languages'},
                                {label: 'Programming Tools', code: 'programming-tools'},
                                {label: 'Software Development', code: 'software-development'},
                                {label: 'Tech News and Trends', code: 'tech-news-and-trends'},
                                {label: 'Version Control', code: 'version-control'},
                                {label: 'Web Development', code: 'web-development'},
                            ]"
                            :reduce="tags => tags.code" 
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                            multiple
                            v-model="tags"
                        ></v-select>
                        <p class="mt-2 text-lg text-red-600 dark:text-red-500" v-if="errors && errors.tags">
                            <span class="font-medium">*{{errors.tags[0]}}</span>
                        </p>
                    </div>
                    <div>
                        <label class="relative inline-flex items-center cursor-pointer">
                            <input type="checkbox" class="sr-only peer" 
                                true-value="1"
                                false-value="0"
                                v-model="isFeatured"
                                :checked="isFeatured"
                            >
                            <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                            <span class="ms-3 text-lg font-medium text-gray-900 dark:text-gray-300">Featured</span>
                        </label>
                        <p class="mt-2 text-lg text-red-600 dark:text-red-500" v-if="errors && errors.is_featured">
                            <span class="font-medium">*{{errors.is_featured[0]}}</span>
                        </p>
                    </div>
                </div>
                <div class="grid gap-6 mb-6 md:grid-cols-2">
                    <div class="mb-6">
                        <label for="published_at" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Published At</label>
                        <input 
                            type="datetime-local"
                            id="published_at"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                            v-model="publishedAt"
                        >
                        <p class="mt-2 text-lg text-red-600 dark:text-red-500" v-if="errors && errors.published_at">
                            <span class="font-medium">*{{errors.published_at[0]}}</span>
                        </p>
                    </div> 
                    <div class="mb-6">
                        <label for="status" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Status</label>
                            <v-select
                                :options="[{label: 'Draft', code: 'draft'}, {label: 'Published', code: 'published'}]"
                                :reduce="status => status.code" 
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                                v-model="status"
                            ></v-select>
                        <p class="mt-2 text-lg text-red-600 dark:text-red-500" v-if="errors && errors.status">
                            <span class="font-medium">*{{errors.status[0]}}</span>
                        </p>
                    </div> 
                </div>
                <div v-if="processing == true">
                    <div role="status">
                        <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-teal-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <button v-if="processing == false" v-on:click="submit" class="text-white bg-teal-700 hover:bg-teal-800 focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-lg w-full sm:w-auto px-5 py-2.5 text-center dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-teal-800 mx-1">Submit</button>
                <button v-if="processing == false" type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-lg w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 mx-1" @click="resetInputs">Cancel</button>
            </form> 
        </div>
        <hr class="w-full h-px my-4 bg-gray-200 border-0 dark:bg-gray-700">
        <div class="w-full px-2">
            <h4 class="text-2xl font-bold dark:text-white">List of Articles</h4>
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg p-4">
                <div class="flex items-center justify-between flex-column flex-wrap md:flex-row space-y-4 md:space-y-0 pb-4 bg-white dark:bg-gray-900">
                    <div class="flex items-center justify-between space-x-2">
                        <div>
                            <label for="per_page" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Entries</label>
                            <select id="per_page" v-model="per_page" @change="retrieveArticles" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <div>
                            <label for="searchTag" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tags</label>
                            <select id="searchTag" v-model="searchTag" @change="retrieveArticles" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="">All</option>
                                <option value="coding-tips-and-tricks">Coding Tips and Tricks</option>
                                <option value="databases">Database</option>
                                <option value="learning-resources">Learning Resources</option>
                                <option value="mobile-app-development">Mobile App Development</option>
                                <option value="programming">Programming</option>
                                <option value="programming-languages">Programming Languages</option>
                                <option value="programming-tools">Programming Tools</option>
                                <option value="software-development">Software Development</option>
                                <option value="tech-news-and-trends">Tech News and Trends</option>
                                <option value="version-control">Version Control</option>
                                <option value="web-development">Web Development</option>
                            </select>
                        </div>
                        <div>
                            <label for="searchTechnology" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Technologies</label>
                            <select id="searchTechnology" v-model="searchTechnology" @change="retrieveArticles" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="">All</option>
                                <option v-for="technology in technologies" :value="technology.id">
                                    {{ technology.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <label for="table-search" class="sr-only">Search</label>
                    <div class="relative">
                        <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <input v-model="searchKey" @change="search" type="text" id="table-search-users" class="block p-2 ps-10 text-lg text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-teal-500 focus:border-teal-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500" placeholder="Search">
                    </div>
                </div>
                <table class="w-full text-lg text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3" width="45%">
                                Title
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Slug
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Published At
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Status
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" v-for="article in articles">
                            <td scope="row" class="flex items-center px-6 py-4 text-gray-900 dark:text-white">
                                <img class="w-10 h-10 rounded-full" :src="'/storage/'+article.og_image" alt="">
                                <div class="ps-3">
                                    <div class="text-base font-semibold">{{article.title}}</div>
                                    <div class="font-normal text-gray-500">{{article.author.name}}</div>
                                </div>  
                            </td>
                            <td class="px-6 py-4">
                                <a :href="'/'+article.slug" target="_blank">
                                    {{article.slug}}
                                </a>
                            </td>
                            <td class="px-6 py-4">
                                {{publishedDate(article.published_at)}}
                            </td>
                            <td class="px-6 py-4">
                                <div class="flex items-center">
                                    <div class="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div> {{article.status.toUpperCase()}}
                                </div>
                            </td>
                            <td class="flex px-6 py-4">
                                <button type="button" class="px-1 font-medium text-teal-600 dark:text-teal-500 hover:underline" @click="editArticle(article.id)">
                                    <svg class="w-6 h-6 text-teal-600 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 21">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7.418 17.861 1 20l2.139-6.418m4.279 4.279 10.7-10.7a3.027 3.027 0 0 0-2.14-5.165c-.802 0-1.571.319-2.139.886l-10.7 10.7m4.279 4.279-4.279-4.279m2.139 2.14 7.844-7.844m-1.426-2.853 4.279 4.279"/>
                                    </svg>
                                </button>
                                <button type="button" class="px-1 font-medium text-red-600 dark:text-teal-500 hover:underline" @click="confirmDelete(article.id)">
                                    <svg class="w-6 h-6 text-red-600 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"/>
                                    </svg>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <nav class="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4" aria-label="Table navigation">
                <span class="text-sm font-normal text-gray-500 dark:text-gray-400">
                    Showing
                    <span class="font-semibold text-gray-900 dark:text-white">{{articlesShownFrom || 0 }} to {{articlesShownUntil || 0 }}</span>
                    of
                    <span class="font-semibold text-gray-900 dark:text-white">{{totalArticles || 0 }}</span>
                </span>
                <ul class="inline-flex items-stretch -space-x-px">
                    <li>
                        <button :disabled="(current_page <= 1)" @click="updateArticlePage(current_page - 1)" class="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            <span class="sr-only">Previous</span>
                            <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                        </button>
                    </li>
                    <li>
                        <label aria-current="page" class="flex items-center justify-center text-sm z-10 py-2 px-3 leading-tight text-primary-600 bg-primary-50 border border-primary-300 hover:bg-primary-100 hover:text-primary-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">{{current_page}}</label>
                    </li>
                    <li>
                        <label class="flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">of</label>
                    </li>
                    <li>
                        <label class="flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{{last_page}}</label>
                    </li>
                    <li>
                        <button :disabled="(current_page >= last_page)" @click="updateArticlePage(current_page + 1)" class="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            <span class="sr-only">Next</span>
                            <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                            </svg>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import vSelect from 'vue-select';
import Swal from 'sweetalert2'

import 'sweetalert2/src/sweetalert2.scss'
import '@sweetalert2/themes/minimal/minimal.scss';

export default {
    name: 'app',
    components: {
        vSelect
    },
    data() {
        return {
            config: {
                height: 300,
                fontFamily: {
                    "Georgia, sans-serif": 'Georgia',
                    "Roboto, sans-serif": 'Roboto',
                    "Oswald, sans-serif": 'Oswald',
                    "Montserrat, sans-serif": 'Montserrat',
                    "Open Sans Condensed, sans-serif": 'Open Sans Condensed',
                    "Poppins, sans-serif": 'Poppins',
                },
                fontFamilySelection: true,
                imageUpload: true,
                imageDefaultAlign: 'left',
                imageDefaultDisplay: 'inline-block',
                imageUploadURL: '/article/content-image/upload',
                imageUploadMethod: 'POST',
                imageUploadParams: {
                    _token: document.querySelector('meta[name="csrf-token"]').content
                }
            },
            processing: false,
            errors: [],
            articles: [],
            technologies: [],
            current_page: 1,
            last_page: 2,
            searchKey: null,
            searchTag: null,
            searchTechnology: null,
            per_page: 10,
            articlesShownFrom: 1,
            articleShowUntil: 1,
            totalArticles: 1,
            id: null,
            ogImage: null,
            ogImagePreview: null,
            favicon: null,
            faviconPreview: null,
            featuredVideo: null,
            metaTitle: null,
            metaDescription: null,
            title: null,
            slug: null,
            content: null,
            technology: null,
            tags: null,
            isFeatured: 0,
            publishedAt: null,
            status: null,
        };
    },
    mounted() {
        this.retrieveArticles();
        this.retrieveTechnologies();
    },
    computed: {
        slugified: function() {
            var slug = this.slugify(this.title);
            return slug;
        },
    },
    methods: {
        resetInputs(){
            this.errors = [];
            this.id = null,
            this.ogImage = null;
            this.ogImagePreview = null;
            this.favicon = null;
            this.faviconPreview = null;
            this.featuredVideo = null;
            this.metaTitle = null;
            this.metaDescription = null;
            this.title = null;
            this.content = '';
            this.tags = null;
            this.technology = null;
            this.isFeatured = 0;
            this.publishedAt = null;
            this.status = null;
        },
        onSelectOgImage(event) {
            const files = event.target.files
            let filename = files[0].name
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.ogImagePreview = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.ogImage = files[0]
        },
        onSelectFavicon(event) {
            const files = event.target.files
            let filename = files[0].name
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.faviconPreview = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.favicon = files[0]
        },
        onSelectFeaturedVideo(event) {
            const files = event.target.files
            const fileReader = new FileReader()
            fileReader.readAsDataURL(files[0])
            this.featuredVideo = files[0];
        },
        slugify: function(title) {
            var slug = "";
            if(title != null){
                // Change to lower case
                var titleLower = title.toLowerCase();
                // Letter "e"
                slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
                // Letter "a"
                slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
                // Letter "o"
                slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
                // Letter "u"
                slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
                // Letter "d"
                slug = slug.replace(/đ/gi, 'd');
                // Trim the last whitespace
                slug = slug.replace(/\s*$/g, '');
                // Change whitespace to "-"
                slug = slug.replace(/\s+/g, '-');
            }
            return slug;
        },
        publishedDate: function(date){
            return moment(date).format('MMMM DD, YYYY'); 
        },
        async showAlert(icon = 'info', message = 'Success.', timer = 7000){
            Swal.fire({
                position: 'top-end',
                icon: icon,
                title: message,
                showConfirmButton: false,
                toast: true,
                timer: timer
            });
        },
        async retrieveArticles(){

            if(this.current_page > this.last_page){ return; }

            try {
                if((this.current_page == 1 || (this.current_page <= this.last_page))){

                    let params = 'per_page='+this.per_page+'&page='+this.current_page;

                    if(this.searchKey != null){
                        params += '&keyword='+this.searchKey;
                    }

                    // To be added
                    console.log(this.searchTag);
                    if(this.searchTag != null){
                        params += '&tag='+this.searchTag;
                    }

                    // To be added
                    if(this.searchTechnology != null){
                        params += '&technology='+this.searchTechnology;
                    }

                    let response = await axios.get('articles/list/datatable?'+params);
                    this.articles = response.data.data;
                    this.last_page = response.data.last_page;

                    this.articlesShownFrom = response.data.from;
                    this.articlesShownUntil = response.data.to;
                    this.totalArticles = response.data.total;
                }
            } catch (error) {
                if(error.response.data.errors){
                    const errors = error.response.data.errors;
                    for (const key in errors) {
                        console.log(errors[key])
                    }
                }
            }
        },
        search(page){
            this.current_page = 1;
            this.retrieveArticles();
        },
        updateArticlePage(page){
            this.current_page = page;
            this.retrieveArticles();
        },
        async retrieveTechnologies(){
            try {

                let response = await axios.get('technologies/list/all');

                this.technologies = [];

                const technologyList = response.data
                for (const technology in technologyList) {
                    this.technologies.push({
                        id: technologyList[technology].id,
                        name: technologyList[technology].name
                    });
                }
            } catch (error) {
                if(error.response.data.errors){
                    const errors = error.response.data.errors;
                    for (const key in errors) {
                        console.log(errors[key])
                    }
                }
            }
        },
        async editArticle(id){
            try {
                let response = await axios.get('articles/'+id+'/edit');
                this.id = response.data.id;
                this.ogImagePreview = '/storage/'+response.data.og_image;
                this.faviconPreview = '/storage/'+response.data.favicon;
                this.metaTitle = response.data.meta_title;
                this.metaDescription = response.data.meta_description;
                this.title = response.data.title;
                this.content = response.data.content;
                this.slug = response.data.slug;
                this.technology = response.data.technology_id
                
                this.tags = [];
                for (let index = 0; index < response.data.tags.length; index++)
                {
                    this.tags.push(response.data.tags[index].slug.en);
                }
                
                this.isFeatured = response.data.featured;
                this.publishedAt = moment(response.data.published_at).format('YYYY-MM-DDThh:mm');
                this.status = response.data.status;
            } catch (error) {
                console.error(error);
            }
        },
        async confirmDelete(id){
            try {
                Swal.fire({
                title: 'Are you sure you want to delete the record?',
                icon: 'question',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Proceed',
                confirmButtonColor: '#3085d6',
                denyButtonText: `Cancel`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteArticle(id);                                  
                    }
                })

            } catch (error) {
                console.log(error);
            }
        },
        async deleteArticle(id){
            try {
                let response = await axios.delete('articles/'+id,{
                                    _token: document.querySelector('meta[name="csrf-token"]').content })
                this.retrieveArticles();
            } catch (error) {
                console.log(error);
            }
        },
        async retrieveTags(){
            try {
                let response = await axios.get('tags/list');
                this.tags = response.data;
            } catch (error) {
                if(error.response.data.errors){
                    const errors = error.response.data.errors;
                    for (const key in errors) {
                        console.log(errors[key])
                    }
                }
            }
        },
        submit(e){
            e.preventDefault();
            this.id == null ? this.saveArticle() : this.updateArticle();
        },
        async saveArticle(){
            let self = this;
            this.processing = true;

            await axios.post('articles', {  
                _token: document.querySelector('meta[name="csrf-token"]').content,
                og_image: this.ogImage,
                favicon: this.favicon,
                featured_video: this.featuredVideo,
                meta_title: this.metaTitle,
                meta_description: this.metaDescription,
                title: this.title,
                slug: this.slugified,
                technology: this.technology,
                content: this.content,
                tags: this.tags,
                is_featured: this.isFeatured,
                published_at: this.publishedAt,
                status: this.status
            },
            {
                headers: {'Content-Type': 'multipart/form-data'}
            })  
            .then(function () {  
                self.resetInputs();
                self.retrieveArticles();
                self.showAlert('success', 'Record saved.');
            })  
            .catch(function (error) {  
                self.errors = error.response.data.errors;  
            });
            
            this.processing = false;
        },
        async updateArticle(){

            let self = this;
            this.processing = true;

            await axios.post('articles/'+this.id, {
                _token: document.querySelector('meta[name="csrf-token"]').content,
                _method: 'patch',
                og_image: this.ogImage,
                favicon: this.favicon,
                featured_video: this.featuredVideo,
                meta_title: this.metaTitle,
                meta_description: this.metaDescription,
                title: this.title,
                slug: this.slug,
                technology: this.technology,
                content: this.content,
                tags: this.tags,
                is_featured: this.isFeatured,
                published_at: this.publishedAt,
                status: this.status
            },
            {
                headers: {'Content-Type': 'multipart/form-data'}
            })  
            .then(function () {  
                self.resetInputs();
                self.retrieveArticles();
                self.showAlert('success', 'Record saved.');
            })  
            .catch(function (error) {  
                self.errors = error.response.data.errors;  
            }); 

            this.processing = false;
        }
    },
}
</script>
<style lang="css">
@import 'vue-select/dist/vue-select.css';
</style>