<template lang="">
    <div class="flex flex-wrap max-w-screen-xl mx-auto p-4">
        <div class="justify-center w-full md:w-3/4">
            <h1 class="text-5xl text-center font-semibold dark:text-white my-4" v-if="currentTag != ''">{{currentTag.name.en}}</h1>
            <div v-if="articles.length > 0" class="grid grid-cols-1 md:grid-cols-3 mx-auto">
                <ArticleCard v-for="article in articles" 
                    :article="article"
                />
            </div>
            <div class="flex w-full mx-2 mb-2" v-show="loading">
                <div class="flex w-full" role="status">
                    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-teal-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span class="m-2">Loading...</span>
                </div>
            </div>
            <div v-if="articles.length > 0" class="flex flex-col items-center pt-4">
                <span class="text-md dark:text-gray-400">
                    Showing <span class="font-semibold text-gray-900 dark:text-white">1</span> to <span class="font-semibold text-gray-900 dark:text-white">{{ articlesShown }}</span> of <span class="font-semibold text-gray-900 dark:text-white">{{ articleCount}}</span>
                </span>
            </div>
            <div v-if="articles.length === 0 && loading == false" class="justify-center text-center w-full">
                <p class="my-3 text-lg md:text-xl dark:text-gray-400">No results found.</p>
            </div>
        </div>
        
        <div class="w-full md:w-1/4 pl-2 hidden md:flex md:flex-col">
            <a v-if="tags.length > 0" href="#" class="sticky top-24 block max-w-sm max-h-min p-6 border-0 border-gray-200 rounded-lg shadow">
                <h2 class="mb-2 text-2xl font-bold tracking-tight text-dark dark:text-white">Topics you make like:</h2>
                <ul class="flex flex-wrap items-center justify-start text-gray-900 dark:text-white">
                    <li v-for="tag in tags" class="mx-1 my-4">
                        <a :href="'/tag/'+tag.slug.en" class="text-dark bg-gray-300 hover:bg-gray-400 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-md px-5 py-4 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800" :class="{ 'bg-gray-300': tag.slug.en != $route.params.slug, 'bg-teal-500': tag.slug.en == $route.params.slug }">{{tag.name.en}}</a>
                    </li>
                </ul>
            </a>
        </div>
        <transition name="fade">
            <div id="pagetop" class="fixed right-7 bottom-24" v-show="scY > 300" @click="scrollToTop">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" class="opacity-50 hover:opacity-100" viewBox="0 0 512 512">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM377 271c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-87-87-87 87c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 167c9.4-9.4 24.6-9.4 33.9 0L377 271z"/>
                </svg>
            </div>
        </transition>
    </div>
</template>
<script>
import ArticleCard from './cards/ArticleCard.vue';

export default {
    name: 'app',
    components:{
        ArticleCard
    },
    data() {
        return {
            scTimer: 0,
            scY: 0,
            searchKey: null,
            loading: false,
            currentTag: '',
            tags: [],
            articles: [],
            articlesShown: 1,
            articleCount: 1,
            current_page: 1,
            last_page: 2
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
        if(this.$route.params.slug == 'all'){
            window.addEventListener('scrollend', this.retrieveArticles);
        }
    },
    unmounted () {
        if(this.$route.params.slug == 'all'){
            window.removeEventListener('scrollend', this.retrieveArticles);
        }
    },
    mounted() {
        if(this.$route.params.slug == 'all'){
            this.retrieveArticles();
        }else{
            this.searchArticlesByTag(this.$route.params.slug);
        }
        this.retrieveTag();
        this.retrieveTags();
    },
    methods: {
        async retrieveTags(){
            try {
                let response = await axios.get('../tags/featured-list');
                this.tags = response.data;
            } catch (error) {
                if(error.response.data.errors){
                    const errors = error.response.data.errors;
                    for (const key in errors) {
                        console.log(errors[key])
                    }
                }
            }
        },
        async retrieveArticles(){

            if(this.current_page > this.last_page){ return; }
            
            this.loading = true;

            try {

                if((this.current_page == 1 || (this.current_page <= this.last_page)) && this.searchKey == null){
                    let response = await axios.get('../articles/list?page='+this.current_page);
                    
                    // this.articles.push(response.data.data);
                    this.last_page = response.data.last_page;
                    response.data.data.forEach((value, index) => {
                        this.articles.push(value);
                    });
                    this.articlesShown = response.data.to;
                    this.articleCount = response.data.total;
                    this.current_page++; 
                }
            } catch (error) {
                if(error.response.data.errors){
                    const errors = error.response.data.errors;
                    for (const key in errors) {
                        console.log(errors[key])
                    }
                }
            }

            this.loading = false;
        },
        async searchArticles(){
            this.loading = true;
            let response = await axios.post('../search', {
                _token: document.querySelector('meta[name="csrf-token"]').content,
                keyword: this.searchKey
            })
            .catch(function (error) {  
                console.log(error);
            });
            this.articlesShown = response.data.length;
            this.articleCount = response.data.length;
            this.articles = response.data;
            this.loading = false;
        },
        async searchArticlesByTag(tag){
            this.loading = true;
            let response = await axios.post('../search', {
                _token: document.querySelector('meta[name="csrf-token"]').content,
                tag: tag
            })
            .catch(function (error) {  
                console.log(error);
            });

            this.articlesShown = response.data.length;
            this.articleCount = response.data.length;
            this.articles = response.data;
            this.loading = false;
        },
        async retrieveTag(){
            try {
                let response = await axios.get('../tags/find/'+this.$route.params.slug);
                this.currentTag = response.data;
            } catch (error) {
                if(error.response.data.errors){
                    const errors = error.response.data.errors;
                    for (const key in errors) {
                        console.log(errors[key])
                    }
                }
            }
        },
        handleScroll() {
            if (this.scTimer) return;
            this.scTimer = setTimeout(() => {
            this.scY = window.scrollY;
            clearTimeout(this.scTimer);
            this.scTimer = 0;
            }, 100);
        },
        scrollToTop() {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        },
    },
}
</script>
<style lang="">
    
</style>